// © 2020 Will Burklund. All rights reserved.
import React from 'react';
import { Header, Item, Segment, List, ListItem } from 'semantic-ui-react';
import REPAY from '../Common/Images/REPAY.png';
import TuitionFit from '../Common/Images/TuitionFit.svg';
import wburklunddotcom from '../Common/Images/logo.png';
import QCVOC from '../Common/Images/QCVOC.png';
import VMware from '../Common/Images/VMware.png';
import './Experience.css';

const Experience = () => (
    <Segment>
        <Item.Group className="experienceItems" divided style={{paddingTop: "5rem"}}>
            <Item style={{padding: "5rem"}}>
                <Item.Image className="experienceLogo" src={REPAY} />
                
                <Item.Content>
                    <Item.Header>REPAY</Item.Header>
                    <Item.Meta>
                        Full Stack Developer (December 2019 - Current)
                    </Item.Meta>
                    <Item.Description>        
                        <List bulleted>
                            <ListItem>Resolved inefficiencies in batch processing application to reduce processing time by 80%</ListItem>
                            <ListItem>Resolved cross-site request forgery vulnerabilities as part of security audit remediation with a global ASP.NET MVC filter underpinned by HMAC tokens</ListItem>
                            <ListItem>Streamlined modification and deployment of web applications from days to hours by clearing technical debt and creating CI/CD pipelines</ListItem>
                            <ListItem>Setup PagerDuty monitoring for important processes and responded to more than 50 alerts</ListItem>
                            <ListItem>Created data flow and entity relationship diagrams for existing project</ListItem>
                        </List>
                    </Item.Description>
                </Item.Content>
            </Item>
            <Item style={{padding: "5rem"}}>
                <Item.Image className="experienceLogo" src={wburklunddotcom} />
                
                <Item.Content>
                    <Item.Header>wburklund.com</Item.Header>
                    <Item.Meta>
                        Independent Project (June 2020 - July 2020)
                    </Item.Meta>
                    <Item.Description>
                        <Header as="h4">Part interactive resume, part passion project, all state-of-the-art AWS/React. Managed by Terraform.</Header>
                        <List bulleted>
                            <ListItem>Zero server instances, 100% multi-continent availability enabled by Amazon CloudFront and S3</ListItem>
                            <ListItem>Javascript compressed by more than 90% with Webpack pipeline</ListItem>
                            <ListItem>Near-instantaneous page load times with React SPA</ListItem>
                            <ListItem>Operating costs below $1/month</ListItem>
                        </List>
                    </Item.Description>
                </Item.Content>
            </Item>
            <Item style={{padding: "5rem"}}>
                <Item.Image className="experienceLogo" src={TuitionFit} />
                
                <Item.Content>
                    <Item.Header>TuitionFit</Item.Header>
                    <Item.Meta>
                        Contractor (May 2019 - May 2020)
                    </Item.Meta>
                    <Item.Description>
                        <List bulleted>
                            <ListItem>Taught self ASP.NET MVC / Microsoft SQL Server and introduced new feature in less than two weeks</ListItem>
                            <ListItem>Devised custom AWS load balancer configuration to resolve Elastic Beanstalk health check issue</ListItem>
                            <ListItem>Created Selenium test suite comprising more than 50 page navigations</ListItem>
                        </List>
                    </Item.Description>
                </Item.Content>
            </Item>
            <Item style={{padding: "5rem"}}>
                <Item.Image className="experienceLogo" src={QCVOC} />
                
                <Item.Content>
                    <Item.Header>Quad Cities Veterans Outreach Center</Item.Header>
                    <Item.Meta>
                        QC Coders Open Source Project (August 2018 – April 2019)
                    </Item.Meta>
                    <Item.Description>
                    An open source event management application created for Quad Cities Veterans Outreach Center. Includes a Javascript/React frontend, C#/.NET Core backend, PostgreSQL database, and Android mobile application.
                        <List bulleted>
                            <ListItem>Analyzed and optimized Javascript bundle to reduce downloaded bundle size by 80%</ListItem>
                            <ListItem>Designed and developed a mobile application to enable native vision-based barcode scanning for a single page web application. This contribution will reduce hardware costs by 15%</ListItem>
                        </List>
                    </Item.Description>
                </Item.Content>
            </Item>
            <Item style={{padding: "5rem"}}>
                <Item.Image className="experienceLogo" src={VMware} />
                
                <Item.Content>
                    <Item.Header>VMware Home Lab</Item.Header>
                    <Item.Meta>
                        Independent Project (July 2018)
                    </Item.Meta>
                    A home installation of VMware ESXi hypervisor, running a Sophos UTM virtual firewall and a Debian/NGINX web server
                    <Item.Description>
                        <List bulleted>
                            <ListItem>Zero unplanned downtime excepting power outages</ListItem>
                            <ListItem>Decommissioned in 2020, replaced with wburklund.com website</ListItem>
                        </List>
                    </Item.Description>
                </Item.Content>
            </Item>
        </Item.Group>
        <br />
    </Segment>
);

export default Experience;