// © 2020 Will Burklund. All rights reserved.
import React from 'react';
import { Link, Route } from 'react-router-dom';
import { Image, Menu, Message } from 'semantic-ui-react';
import About from './About/index';
import Experience from './Experience/index';
import logo from './Common/Images/logo.png';

import './App.css';

const App = () => (
  <div className="App">
    <Menu size="large" id="header" className="fixed" inverted>
      <Link to="/" className="cornerLogo">
        <Image src={logo} avatar />
      </Link>
      <Menu inverted className="right">
        <Link className="item" to="/">About</Link>
        <Link className="item" to="/experience">Experience</Link>
        <a className="item" href="mailto:waburklund@gmail.com">Contact</a>
      </Menu>
    </Menu>
    <div className="pageContainer">
      <Route exact path="/">
        <About />
      </Route>
      <Route path="/experience">
        <Experience />
      </Route>
    </div>
    <Menu borderless id="footer" inverted>
      <Message color='black' content='© 2020 Will Burklund. All rights reserved.' />
    </Menu>
  </div>
);

export default App;