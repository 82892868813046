// © 2020 Will Burklund. All rights reserved.
import React from 'react';
import { Card, Header, Image, Segment, CardContent, CardGroup, Divider } from 'semantic-ui-react';
import cloud from '../Common/Images/cloud.jpg';
import devopsLoop from '../Common/Images/devops-loop.svg';
import web from '../Common/Images/web.jpg';
import profile from '../Common/Images/profile.jpg';
import './About.css';

const Intro = () => (
    <React.Fragment>
        <div className="hero">
            <div className="hero__header">
                <h1 className="hero__header__main">Will Burklund</h1>
                <h3 className="hero__header__sub">Software Engineer</h3>
            </div>
            <div className="hero__downarrow">

            </div>
        </div>
        <Segment>
            <br />
            <Image src={profile} centered avatar size="medium" />
            <Header as="h1">Hi!</Header>
            <Header as="h3" className="normalFontWeight blurb">I'm a software engineer based in the <a href='https://en.wikipedia.org/wiki/Quad_Cities'>Quad Cities</a>. My passion is solving big problems and creating great technology.</Header>
            <br />
            <br />
            <Divider />
            <Header as="h2">What I can do</Header>
            <CardGroup itemsPerRow="1" className="horizontal" id="strengthsCardGroup">
                <Card fluid="false" className="strength-card">
                    <Image src={web} />
                    <CardContent className="strength-card__content">
                        <Header as="h3" style={{padding: "2rem"}}>Software</Header>
                        I eat technical challenges for breakfast and specialize in .NET/SQL. 
                    </CardContent>
                </Card>
                <Card fluid="false" className="strength-card">
                <CardContent className="strength-card__content">
                    <Header as="h3" style={{padding: "2rem"}}>Cloud</Header>
                        AWS can save months of procurement time and scale applications to meet any demand.
                    </CardContent>
                    <Image right src={cloud} />
                </Card>
                <Card fluid="false" className="strength-card">
                    <Image src={devopsLoop} />
                    <CardContent className="strength-card__content">
                        <Header as="h3" style={{padding: "2rem"}}>DevOps</Header>
                        Tools like Azure DevOps and Terraform multiply the effectiveness of a team by automating toil.
                    </CardContent>
                </Card>
            </CardGroup>
            <Divider />
            <Header as="h2">Availability</Header>
            <Header as="h3" className="normalFontWeight blurb">I'm currently happily employed! If you'd like to contact me, feel free to send me an <a href='mailto:waburklund@gmail.com'>email</a>.</Header>
            <br />
            <br />
        </Segment>
    </React.Fragment>
);

export default Intro;